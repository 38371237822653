import { ThemeService } from './shared/services/theming.service';
import { Component, ViewChild, Inject } from '@angular/core';
import {
  Router,
  NavigationEnd,
  Event as RouterEvent,
  NavigationStart,
  NavigationCancel,
  NavigationError,
} from '@angular/router';
import { environment } from 'src/environments/environment';
import { GlobalConstants } from './pages/common/global-variables';
import { DOCUMENT } from '@angular/common';
import { AuthenticationService } from './authentication/authentication.service';
import { Title } from '@angular/platform-browser';
import { appConstants } from './app.constants';
declare function require(name: string): any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  shouldScroll = false;
  isLoading = false;
  hideFooter: boolean = false;

  constructor(
    private router: Router,
    public GlobalConstants: GlobalConstants,
    public _auth: AuthenticationService,
    public themeService: ThemeService,
    private titleService: Title,
    @Inject(DOCUMENT) private _document: HTMLDocument
  ) {
    const isTreasures = environment.isDBSTreasures;
    console.log('isTreasures ', isTreasures);
    this.setPageTitle();
    if (sessionStorage.getItem('dbsparams')) {
      this._auth.processDataQuery(
        JSON.parse(sessionStorage.getItem('dbsparams'))
      );
    }
    localStorage.setItem('firstLoad', 'true');
    this.addScript(
      `https://maps.googleapis.com/maps/api/js?key=${environment.gmApiKey}`
    );
    this.router.events.subscribe((e: RouterEvent) => {
      this.navigationInterceptor(e);
    });
    // this.themeService.setThemes('dark')
  }

  // Shows and hides the loading spinner during RouterEvent changes
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.isLoading = true;
    }
    if (event instanceof NavigationEnd) {
      this.isLoading = false;
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.isLoading = false;
    }
    if (event instanceof NavigationError) {
      this.isLoading = false;
    }
  }

  checkScroll(event) {
    this.shouldScroll = event;
  }

  isFlights: Boolean = false;
  ngOnInit() {
    if (this.GlobalConstants && this.GlobalConstants.data) {
      // if (this.GlobalConstants.data.font_family) {
      //   this.addStyleSheet(this.GlobalConstants.data.font_family.link)
      // }
      if (
        this.GlobalConstants.data.logo &&
        this.GlobalConstants.data.logo.favicon
      ) {
        this._document
          .getElementById('appFavicon')
          .setAttribute('href', this.GlobalConstants.data.logo.favicon);
      }
      if (
        this.GlobalConstants.data.colors &&
        this.GlobalConstants.data.colors.header_bg_color
      ) {
        this._document
          .getElementById('appColor')
          .setAttribute(
            'content',
            this.GlobalConstants.data.colors.header_bg_color
          );
      }
      if (this.GlobalConstants.data.assets.client_css) {
        // require('style-loader!./custom-styles.scss');
        // require("style-loader!../assets/scss/client-styles/citi.scss");
        // require("style!css!less!../assets/scss/client-styles/"+this.GlobalConstants.data.assets.client_css)
        // require(`style!css!scss!../assets/scss/client-styles/${this.GlobalConstants.data.assets.client_css}`);
        // this.addStyleSheet('../assets/scss/client-styles/'+this.GlobalConstants.data.assets.client_css)
      }
    }
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      // this.isFlights = window.location.pathname.includes('flights/listing')
      this.hideFooter = window.location.pathname.includes('points-xchange');
      window.scrollTo(0, 0);
    });
  }

  public setPageTitle(): void {
    this.titleService.setTitle(
      window.location.hostname.includes(appConstants.dbsTreasuresUrlKeyword)
        ? appConstants.dbsTreasuresTitle
        : appConstants.dbsDelightsTitle
    );
  }

  addStyleSheet(fontLink) {
    var headID = document.getElementsByTagName('head')[0];
    var link = document.createElement('link');
    link.type = 'text/css';
    link.rel = 'stylesheet';
    link.id = 'widget_styles';
    headID.appendChild(link);
    link.href = fontLink;
  }
  addScript(linkurl) {
    var headID = document.getElementsByTagName('head')[0];
    var link = document.createElement('script');
    link.src = linkurl;
    link.async = true;
    headID.appendChild(link);
  }
}
